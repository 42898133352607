import Autoplay from "embla-carousel-autoplay";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "~/components/ui/carousel";
import { Image } from "~/components/Image";
import { isEmpty } from "~/lib/utils";

export function Slider({ images, ...props }) {
  return (
    <div className="block block__slider">
      <Carousel
        plugins={[
          Autoplay({
            delay: 3000,
          }),
        ]}
        opts={{
          align: "start",
          loop: true,
        }}
      >
        <CarouselContent>
          {images.map((image, index) => {
            const pageLink = image.page_link;
            const externalLink = image.external_link;
            const ItemTag = isEmpty(pageLink) && isEmpty(externalLink) ? "div" : "a";
            let itemTagProps = {};

            if (!isEmpty(pageLink)) {
              itemTagProps = {
                href: pageLink,
              };
            }

            if (!isEmpty(externalLink)) {
              itemTagProps = {
                href: externalLink,
                target: "_blank",
                rel: "noopener noreferrer",
              };
            }

            return (
              <CarouselItem key={index}>
                <ItemTag {...itemTagProps}>
                  <div className="aspect-[4/3] md:aspect-[1440/640] relative">
                    <Image
                      src={image.image.url}
                      alt={image.image.alt}
                      width={2000}
                      className="object-cover absolute h-full w-full"
                      loading={index === 0 ? "eager" : "lazy"}
                    />
                    {/* <img src={image.image.url} alt={image.image.alt} className="object-cover absolute h-full w-full" /> */}
                  </div>
                </ItemTag>
              </CarouselItem>
            );
          })}
        </CarouselContent>
        {images.length > 1 && (
          <>
            <CarouselPrevious />
            <CarouselNext />
          </>
        )}
      </Carousel>
    </div>
  );
}
